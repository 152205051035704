export const unitsAndConversions = {
  mass: [
    {
      label: "ng",
      fullName: "Nanogram",
      value: "nanogram",
      convertToBase: (input: number) => input / 1000000000,
      convertFromBase: (input: number) => input * 1000000000,
    },
    {
      label: "μg",
      fullName: "Microgram",
      value: "microgram",
      convertToBase: (input: number) => input / 1000000,
      convertFromBase: (input: number) => input * 1000000,
    },
    {
      label: "mg",
      fullName: "Milligram",
      value: "milligram",
      convertToBase: (input: number) => input / 1000,
      convertFromBase: (input: number) => input * 1000,
    },
    {
      label: "g",
      fullName: "Gram",
      value: "gram",
      convertToBase: (input: number) => input,
      convertFromBase: (input: number) => input,
    },
    {
      label: "kg",
      fullName: "Kilogram",
      value: "kilogram",
      convertToBase: (input: number) => input * 1000,
      convertFromBase: (input: number) => input / 1000,
    },
    {
      label: "lbs",
      fullName: "Pounds",
      value: "pounds",
      convertToBase: (input: number) => input * 453.592,
      convertFromBase: (input: number) => input / 453.592,
    },
    {
      label: "oz",
      fullName: "Ounce",
      value: "ounce",
      convertToBase: (input: number) => input * 28.3495,
      convertFromBase: (input: number) => input / 28.3495,
    },
    {
      label: "ton",
      fullName: "Metric ton",
      value: "metricTon",
      convertToBase: (input: number) => input * 1000000,
      convertFromBase: (input: number) => input / 1000000,
    },
  ],
  temperature: [
    {
      label: "°C",
      fullName: "Degr. Celcius",
      value: "celcius",
      convertToBase: (input: number) => input,
      convertFromBase: (input: number) => input,
    },
    {
      label: "K",
      fullName: "Kelvin",
      value: "kelvin",
      convertToBase: (input: number) => input - 273.15,
      convertFromBase: (input: number) => input + 273.15,
    },
    {
      label: "°F",
      fullName: "Degr. Fahrenheit",
      value: "fahrenheit",
      convertToBase: (input: number) => (input - 32) / 1.8,
      convertFromBase: (input: number) => input * 1.8 + 32,
    },
  ],
  pressure: [
    {
      label: "mbar",
      fullName: "Millibar",
      value: "millibar",
      convertToBase: (input: number) => input / 1000,
      convertFromBase: (input: number) => input * 1000,
    },
    {
      label: "bar",
      value: "bar",
      convertToBase: (input: number) => input,
      convertFromBase: (input: number) => input,
    },
    {
      label: "N/m²",
      fullName: "Newton / square meter",
      value: "newtonPerSquareMeter",
      convertToBase: (input: number) => input / 100000,
      convertFromBase: (input: number) => input * 100000,
    },
    {
      label: "Pa",
      fullName: "Pascal",
      value: "pascal",
      convertToBase: (input: number) => input / 100000,
      convertFromBase: (input: number) => input * 100000,
    },
    {
      label: "kPa",
      fullName: "Kilo Pascal",
      value: "kiloPascal",
      convertToBase: (input: number) => input / 100,
      convertFromBase: (input: number) => input * 100,
    },
    {
      label: "atm",
      fullName: "Atmosphere",
      value: "atmosphere",
      convertToBase: (input: number) => input / 0.986923267,
      convertFromBase: (input: number) => input * 0.986923267,
    },
    {
      label: "psi",
      fullName: "Pounds / square inch",
      value: "poundsPerSquareInch",
      convertToBase: (input: number) => input / 14.503773773,
      convertFromBase: (input: number) => input * 14.503773773,
    },
    {
      label: "Torr",
      value: "torr",
      convertToBase: (input: number) => input / 750.061682704,
      convertFromBase: (input: number) => input * 750.061682704,
    },
  ],
  volume: [
    {
      label: "nL",
      fullName: "Nanoliter",
      value: "nanoliter",
      convertToBase: (input: number) => input / 1000000000,
      convertFromBase: (input: number) => input * 1000000000,
    },
    {
      label: "μL",
      fullName: "Microliter",
      value: "microliter",
      convertToBase: (input: number) => input / 1000000,
      convertFromBase: (input: number) => input * 1000000,
    },
    {
      label: "mL",
      fullName: "Milliliter",
      value: "milliliter",
      convertToBase: (input: number) => input / 1000,
      convertFromBase: (input: number) => input * 1000,
    },
    {
      label: "cL",
      fullName: "Centiliter",
      value: "centiliter",
      convertToBase: (input: number) => input / 100,
      convertFromBase: (input: number) => input * 100,
    },
    {
      label: "dL",
      fullName: "Deciliter",
      value: "deciliter",
      convertToBase: (input: number) => input / 10,
      convertFromBase: (input: number) => input * 10,
    },
    {
      label: "L",
      fullName: "Liter",
      value: "liter",
      convertToBase: (input: number) => input,
      convertFromBase: (input: number) => input,
    },
    {
      label: "cm³",
      fullName: "Cubic centimeter (cc)",
      value: "cubicCentimeter",
      convertToBase: (input: number) => input / 1000,
      convertFromBase: (input: number) => input * 1000,
    },
    {
      label: "m³",
      fullName: "Cubic meter",
      value: "cubicmeter",
      convertToBase: (input: number) => input * 1000,
      convertFromBase: (input: number) => input / 1000,
    },
    {
      label: "gal",
      fullName: "Gallon (US)",
      value: "gallon",
      convertToBase: (input: number) => input * 3.78541178,
      convertFromBase: (input: number) => input / 3.78541178,
    },
  ],
  concentration: [
    {
      label: "nM",
      fullName: "Nanomolar",
      value: "nanomolar",
      convertToBase: (input: number) => input / 1000000000,
      convertFromBase: (input: number) => input * 1000000000,
    },
    {
      label: "μM",
      fullName: "Micromolar",
      value: "micromolar",
      convertToBase: (input: number) => input / 1000000,
      convertFromBase: (input: number) => input * 1000000,
    },
    {
      label: "mM",
      fullName: "Millimolar",
      value: "millimolar",
      convertToBase: (input: number) => input / 1000,
      convertFromBase: (input: number) => input * 1000,
    },
    {
      label: "M",
      fullName: "Molar",
      value: "molar",
      convertToBase: (input: number) => input,
      convertFromBase: (input: number) => input,
    },
  ],
};
