import { Divider, TextField, Typography, useTheme } from "@mui/material";
import { CommonIonsTables } from "./CommonIonsTables";
import { useState, ChangeEvent } from "react";

export const CommonIons = () => {
  const theme = useTheme();
  const [exactMass, setExactMass] = useState("");
  const [observedMz, setObservedMz] = useState("");
  const [deviation, setDeviation] = useState("");

  const handleChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const target = e.target.name;
    let value = e.target.value;
    value = value.replace(",", ".");
    if (value.match(/[^0-9.]/g)) return;
    if (value.match(/[.]/g) && value.match(/[.]/g)!.length > 1) return;

    switch (target) {
      case "exactMass":
        setExactMass(value);
        break;
      case "observedMz":
        setObservedMz(value);
        if (deviation === "") setDeviation("0.9");
        break;
      case "deviation":
        setDeviation(value);
        break;
    }
  };

  return (
    <>
      <Typography variant="h5" align="center">
        Common ions generated by soft ionization methods
      </Typography>
      <Divider />
      <Typography style={{ margin: "20px 0px" }} align={"center"}>
        Based on the exact mass of your target, this tool calculates a variety
        of masses that you might observe when using soft ionization mass
        spectroscopy. If you enter the observed m/z as well, matching values
        will be highlighted.
      </Typography>
      <fieldset
        style={{
          margin: "auto",
          width: "650px",
          border: `1px solid ${theme.palette.divider}`,
          borderRadius: "5px",
          marginBottom: "20px",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
        }}
      >
        <legend style={{ margin: "auto" }}>
          <b>Input</b>
        </legend>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <TextField
            style={{ width: "250px" }}
            name="exactMass"
            label="Exact mass (u)"
            size="small"
            inputProps={{ maxLength: 10 }}
            value={exactMass}
            onChange={handleChange}
          />
          <Divider orientation="vertical" flexItem={true} />
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              gap: "10px",
            }}
          >
            <TextField
              style={{ width: "250px" }}
              name="observedMz"
              label="Observed m/z (optional)"
              size="small"
              inputProps={{ maxLength: 10 }}
              value={observedMz}
              onChange={handleChange}
            />
            <TextField
              style={{ width: "100px" }}
              name="deviation"
              label="Deviation"
              size="small"
              inputProps={{ maxLength: 6 }}
              value={deviation}
              onChange={handleChange}
            />
          </div>
        </div>
      </fieldset>
      <CommonIonsTables
        exactMass={parseFloat(exactMass)}
        observedMz={parseFloat(observedMz)}
        deviation={parseFloat(deviation)}
      />
    </>
  );
};
